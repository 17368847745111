'use strict';

import $ from 'jquery';
import slick from 'slick-carousel';

export default function() {

    $(".slider--main").each(function() {

        let pagination = $('.slider__pagination', this);

        $(".slider__content", this).slick({
            infinite: true,
            arrows: true,
            prevArrow: $('.slider__prev', this),
            nextArrow: $('.slider__next', this),
            slide: '.slider__item',
            speed: 500,
            fade: true,
            dots: true,
            appendDots: pagination,
            adaptiveHeight: true
        });
    });

    $(".slider--products").each(function() {

        let pagination = $('.slider__pagination', this);

        $(".slider__content", this).slick({
            infinite: true,
            arrows: true,
            prevArrow: $('.slider__prev', this),
            nextArrow: $('.slider__next', this),
            slide: '.slider__item',
            speed: 500,
            fade: false,
            dots: true,
            appendDots: pagination,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1001,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 501,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1

                    }
                }
            ]
        });
    });

}
