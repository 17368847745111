'use strict';

import $ from 'jquery';

export default function() {

       let width = $(window).width(),
           child = $('.has-child');

       if (width < 1000) {
           child.addClass('has-child-mobile');
       } else {
           child.removeClass('has-child-mobile');
       }

       $(window).on('resize',function () {
           let width = $(window).width();

           if (width < 1000) {
               child.addClass('has-child-mobile');
           } else {
               child.removeClass('has-child-mobile');
           }
       });

        $('.nav-toggle').on('click', function () {
            $('.nav__list').slideToggle(200);
            return false;
        });

       $('.has-child-mobile').on('click', function () {
           $('.child').slideToggle(200);
           return false;
       });
}
